moment = require 'moment'
classnames = require 'classnames'
{ Link } = require 'react-router-dom'
BaseComponent = require 'components/BaseComponent'
styles = require './agent-list-item'
{ capitalizeFirstLetter } = require 'lib/utils'
{ ROLES, IDLE } = require 'constants/Agents'
AgentsActionCreator = require 'actions/AgentsActionCreator'
AgentStatisticActionCreator = require 'actions/AgentStatisticActionCreator'
DropdownList = require 'react-widgets/lib/DropdownList'


class ShiftAgentComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  removeShiftUser: (id) =>
    setTimeout(
      ->
        AgentsActionCreator.removeShiftUser(id)
      0
    )

  renderIcon: (value) =>
    if value == 'confirmed'
      React.createElement("i", {"className": (styles.rc_confirm)})
    else if value == 'break_at'
      React.createElement("i", {"className": (styles.rc_break_at)})
    else if value == 'no_confirmed'
      React.createElement("i", {"className": (styles.rc_cancel)})

  getStatus: =>
    if @props.agent.break_at
      return 'On Breake'
    else
      return capitalizeFirstLetter @props.agent.status || 'offline'

  onChangeHandler: (shift, field, value) ->
    updatedField = field
    if field == 'created_at'
      value = value.id
      updatedField = 'begin_shift_time'
    if field == 'end_time'
      value = value.id
      updatedField = 'end_shift_time'
    unless shift[field] == value
      AgentStatisticActionCreator.changeShiftStatistic(
        shift.user_id, shift.id, field, value, updatedField
      )

  render: ->
    { agent, dashboardAgents, rollCallsNames } = @props
    rollCalls = rollCallsNames.sort()
    agentRollCall = ['', '', '', '']
    if agent?.rollcalls && rollCalls.length > 0
      rollCalls.forEach (rollCallName, idx) ->
        if agent.rollcalls[rollCallName]
          rc = agent?.rollcalls[rollCallName]
          agentRollCall[idx] = if rc?.confirmed_by then 'break_at' else if rc.confirmed_at then 'confirmed' else if !rc.confirmed_at && rc.isExpiredRollCall then 'no_confirmed'

    { status = 'offline', smstool_agent = {} } = agent
    { id, image, is_dormant, first_name, last_name, role, options = {} } = smstool_agent
    name = smstool_agent?.name || agent?.name
    idle = ''
    idle = 'idle' if is_dormant
    location = options.location || {}
    { city, region } = location
    dashboardAgent = {}
    if id
      dashboardAgent = dashboardAgents[id] || {}
    { new_message, opened, dormant, inactive } = dashboardAgent

    lastShift = smstool_agent?.last_shift || {}
    { begin_shift_time, end_shift_time, min_shift_time, max_shift_time, average_response_time, unique_clients_count, is_changed_by_agent } = lastShift
    userImage = React.createElement("img", { \
      "className": (styles.agent__image),  \
      "src": (require '!file-loader!components/shared/images/unknown.png'),  \
      "alt": 'User image'
    })
    userImage = React.createElement("img", {"className": (styles.agent__image), "src": (image)}) if image
    linkToAgent = "/agents/#{id}/shifts"

    React.createElement("tr", {"className": (styles.agent)},
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_name)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          (userImage),
          React.createElement("p", {"className": (styles.agent__label)},
            (name)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_role)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary)},
            ((ROLES[role] || {}).label || ROLES.newbie_agent.label)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_location)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary)},
            ("#{region || ''} #{city || ''}")
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_status)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("span", {"className": (classnames styles['agent__status-point'],
            "#{styles['agent__status-point_break']}": @props.agent.break_at
            "#{styles['agent__status-point_online']}": status != 'offline' && !@props.agent.break_at
          )}),
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__status, styles.agent__label_secondary)},
            (@getStatus())
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row_min)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
            (idle &&
              React.createElement("span", {"className": (styles.agent__label__notify_panels)}, (idle))
            )
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row_min)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
            (unique_clients_count || '')
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row_min)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
            (if average_response_time then Number.parseFloat(average_response_time).toFixed 2 else '')
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row_min)},
        React.createElement("span", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
          (if begin_shift_time
            minTime = moment(min_shift_time).toDate()
            maxTime = moment(end_shift_time).toDate()
            maxTime = new Date(Math.floor(maxTime / 15 / 1000 / 60) * 15 * 60 * 1000)
            dates = []
            while (minTime < maxTime)
              dates.push({id: minTime, name: moment(minTime).format("HH:mm")})
              minTime = moment(minTime).add(15, 'm').toDate()
            React.createElement(DropdownList, { \
              "containerClassName": (classnames(styles.datepicker, {[styles.datepicker_changed]: (is_changed_by_agent || {}).begin_shift_time})),  \
              "data": (dates),  \
              "dataKey": 'id',  \
              "textField": 'name',  \
              "defaultValue": ({
                id: moment(begin_shift_time).toDate(),
                name: moment(begin_shift_time).format("HH:mm")
              }),  \
              "value": ({
                id: moment(begin_shift_time).toDate(),
                name: moment(begin_shift_time).format("HH:mm")
              }),  \
              "onChange": (@onChangeHandler.bind(@, lastShift, 'created_at'))
            })
           else '')
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row_min)},
        React.createElement("span", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
          (if end_shift_time
            minTime = moment(begin_shift_time).toDate()
            minTime = new Date(Math.ceil(minTime / 15 / 1000 / 60) * 15 * 60 * 1000)
            maxTime = moment(max_shift_time).toDate()
            dates = []
            while (minTime <= maxTime)
              dates.push({id: minTime, name: moment(minTime).format("HH:mm")})
              minTime = moment(minTime).add(15, 'm').toDate()
            React.createElement(DropdownList, { \
              "containerClassName": (classnames(styles.datepicker, {[styles.datepicker_changed]: (is_changed_by_agent || {}).end_shift_time})),  \
              "data": (dates),  \
              "dataKey": 'id',  \
              "textField": 'name',  \
              "defaultValue": ({
                id: moment(end_shift_time).toDate(),
                name: moment(end_shift_time).format("HH:mm")
              }),  \
              "value": ({
                id: moment(end_shift_time).toDate(),
                name: moment(end_shift_time).format("HH:mm")
              }),  \
              "onChange": (@onChangeHandler.bind(@, lastShift, 'end_time'))
            })
           else '')
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row_min)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
            (new_message)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
            (opened)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary, styles.agent__row_center)},
            (dormant)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondar, styles.agent__row_center)},
            (inactive)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row__rc, styles.agent__row_center)},
        React.createElement("span", null, (@renderIcon agentRollCall[0]))
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row__rc, styles.agent__row_center)},
          React.createElement("span", null, (@renderIcon agentRollCall[1]))
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row__rc, styles.agent__row_center)},
        React.createElement("span", null, (@renderIcon agentRollCall[2]))
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_item, styles.agent__row__rc, styles.agent__row_center)},
        React.createElement("span", null, (@renderIcon agentRollCall[3]))
      )
    )

module.exports = ShiftAgentComponent
